function App() {
  return (
    <section className="theme-light section m-0 p-0 backgroundWhiteColor" data-theme="light">
      <div className="fullHeight is-flex is-flex-direction-column is-justify-content-center is-align-items-center m-0 p-0">
        <p className="is-size-4 has-text-weight-bold has-color-black mb-4">Future home of...</p>
          <svg width="608" height="57" viewBox="0 0 608 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M607.249 23.6961L601.567 25.3045C601.209 24.3573 600.682 23.437 599.985 22.5434C599.306 21.632 598.377 20.8814 597.197 20.2917C596.018 19.702 594.508 19.4071 592.667 19.4071C590.147 19.4071 588.047 19.9879 586.367 21.1495C584.705 22.2932 583.874 23.7497 583.874 25.5189C583.874 27.0915 584.446 28.3336 585.59 29.245C586.734 30.1564 588.521 30.9159 590.951 31.5235L597.063 33.0246C600.744 33.9182 603.488 35.2853 605.293 37.126C607.098 38.9488 608 41.2988 608 44.176C608 46.5349 607.321 48.6437 605.963 50.5022C604.622 52.3608 602.746 53.8262 600.333 54.8984C597.921 55.9707 595.115 56.5068 591.916 56.5068C587.717 56.5068 584.241 55.5954 581.489 53.7726C578.737 51.9498 576.994 49.287 576.262 45.7844L582.266 44.2832C582.838 46.4992 583.919 48.1612 585.51 49.2692C587.118 50.3771 589.218 50.9311 591.809 50.9311C594.758 50.9311 597.099 50.3057 598.832 49.0547C600.584 47.7859 601.459 46.2669 601.459 44.4977C601.459 43.068 600.959 41.8707 599.958 40.9056C598.957 39.9227 597.421 39.19 595.348 38.7075L588.485 37.0992C584.714 36.2056 581.944 34.8206 580.175 32.9442C578.424 31.0499 577.548 28.682 577.548 25.8406C577.548 23.5174 578.201 21.4622 579.505 19.6752C580.828 17.8881 582.623 16.4852 584.893 15.4666C587.181 14.448 589.772 13.9386 592.667 13.9386C596.741 13.9386 599.94 14.8322 602.263 16.6193C604.605 18.4063 606.267 20.7653 607.249 23.6961Z" fill="black"/>
            <path d="M548.867 55.6487V14.4745H554.978V20.6935H555.407C556.158 18.6563 557.516 17.0032 559.482 15.7344C561.448 14.4656 563.664 13.8311 566.13 13.8311C566.594 13.8311 567.175 13.8401 567.872 13.858C568.569 13.8758 569.096 13.9026 569.454 13.9384V20.3719C569.239 20.3182 568.748 20.2378 567.979 20.1306C567.229 20.0055 566.434 19.943 565.594 19.943C563.592 19.943 561.805 20.3629 560.232 21.2028C558.678 22.0249 557.444 23.1686 556.533 24.634C555.64 26.0816 555.193 27.7346 555.193 29.5932V55.6487H548.867Z" fill="black"/>
            <path d="M521.062 56.5068C517.095 56.5068 513.673 55.6312 510.795 53.8798C507.936 52.1106 505.729 49.6444 504.174 46.4813C502.637 43.3003 501.869 39.6011 501.869 35.3836C501.869 31.1661 502.637 27.4489 504.174 24.2322C505.729 20.9976 507.891 18.4778 510.661 16.6729C513.449 14.8501 516.702 13.9386 520.419 13.9386C522.563 13.9386 524.681 14.2961 526.772 15.0109C528.863 15.7257 530.766 16.8873 532.482 18.4957C534.197 20.0862 535.564 22.1949 536.583 24.8219C537.602 27.4489 538.111 30.6836 538.111 34.5258V37.2064H506.372V31.7379H531.677C531.677 29.4147 531.213 27.3417 530.283 25.5189C529.372 23.6961 528.067 22.2575 526.37 21.2031C524.69 20.1487 522.706 19.6215 520.419 19.6215C517.899 19.6215 515.719 20.247 513.878 21.498C512.055 22.7311 510.652 24.3394 509.67 26.3231C508.687 28.3067 508.195 30.4334 508.195 32.703V36.3486C508.195 39.4581 508.731 42.094 509.804 44.2564C510.894 46.4009 512.404 48.0361 514.334 49.1619C516.264 50.2699 518.507 50.8239 521.062 50.8239C522.724 50.8239 524.225 50.5916 525.566 50.127C526.924 49.6444 528.094 48.9296 529.077 47.9825C530.06 47.0174 530.82 45.8201 531.356 44.3904L537.468 46.106C536.824 48.179 535.743 50.0019 534.224 51.5745C532.705 53.1292 530.829 54.3445 528.595 55.2201C526.361 56.0779 523.85 56.5068 521.062 56.5068Z" fill="black"/>
            <path d="M475.994 56.5068C472.027 56.5068 468.605 55.6312 465.728 53.8798C462.868 52.1106 460.661 49.6444 459.107 46.4813C457.57 43.3003 456.801 39.6011 456.801 35.3836C456.801 31.1661 457.57 27.4489 459.107 24.2322C460.661 20.9976 462.824 18.4778 465.594 16.6729C468.382 14.8501 471.634 13.9386 475.351 13.9386C477.496 13.9386 479.613 14.2961 481.704 15.0109C483.795 15.7257 485.698 16.8873 487.414 18.4957C489.129 20.0862 490.497 22.1949 491.515 24.8219C492.534 27.4489 493.043 30.6836 493.043 34.5258V37.2064H461.305V31.7379H486.61C486.61 29.4147 486.145 27.3417 485.216 25.5189C484.304 23.6961 483 22.2575 481.302 21.2031C479.622 20.1487 477.639 19.6215 475.351 19.6215C472.831 19.6215 470.651 20.247 468.81 21.498C466.988 22.7311 465.585 24.3394 464.602 26.3231C463.619 28.3067 463.128 30.4334 463.128 32.703V36.3486C463.128 39.4581 463.664 42.094 464.736 44.2564C465.826 46.4009 467.336 48.0361 469.266 49.1619C471.196 50.2699 473.439 50.8239 475.994 50.8239C477.656 50.8239 479.158 50.5916 480.498 50.127C481.856 49.6444 483.027 48.9296 484.009 47.9825C484.992 47.0174 485.752 45.8201 486.288 44.3904L492.4 46.106C491.756 48.179 490.675 50.0019 489.156 51.5745C487.637 53.1292 485.761 54.3445 483.527 55.2201C481.293 56.0779 478.782 56.5068 475.994 56.5068Z" fill="black"/>
            <path d="M430.766 55.6487V14.4745H436.878V20.6935H437.307C438.057 18.6563 439.415 17.0032 441.381 15.7344C443.347 14.4656 445.563 13.8311 448.029 13.8311C448.494 13.8311 449.074 13.8401 449.771 13.858C450.468 13.8758 450.996 13.9026 451.353 13.9384V20.3719C451.139 20.3182 450.647 20.2378 449.879 20.1306C449.128 20.0055 448.333 19.943 447.493 19.943C445.491 19.943 443.704 20.3629 442.132 21.2028C440.577 22.0249 439.344 23.1686 438.432 24.634C437.539 26.0816 437.092 27.7346 437.092 29.5932V55.6487H430.766Z" fill="black"/>
            <path d="M399.215 56.614C396.606 56.614 394.238 56.1226 392.112 55.1397C389.985 54.1389 388.296 52.7003 387.045 50.8239C385.794 48.9296 385.169 46.6422 385.169 43.9615C385.169 41.6026 385.634 39.6904 386.563 38.225C387.492 36.7417 388.734 35.5801 390.289 34.7402C391.844 33.9003 393.559 33.2748 395.436 32.8638C397.33 32.4349 399.233 32.0953 401.145 31.8452C403.647 31.5235 405.676 31.2822 407.23 31.1214C408.803 30.9427 409.947 30.6478 410.662 30.2368C411.394 29.8258 411.761 29.1109 411.761 28.0923V27.8778C411.761 25.233 411.037 23.1778 409.589 21.7124C408.16 20.247 405.988 19.5143 403.075 19.5143C400.055 19.5143 397.687 20.1755 395.972 21.498C394.256 22.8204 393.05 24.2322 392.353 25.7334L386.348 23.5889C387.421 21.087 388.85 19.139 390.637 17.7451C392.442 16.3333 394.408 15.3504 396.535 14.7964C398.679 14.2246 400.788 13.9386 402.861 13.9386C404.183 13.9386 405.702 14.0995 407.418 14.4212C409.151 14.725 410.822 15.3594 412.431 16.3244C414.057 17.2894 415.406 18.7459 416.478 20.6938C417.551 22.6417 418.087 25.2508 418.087 28.5212V55.649H411.761V50.0733H411.439C411.01 50.9669 410.295 51.923 409.294 52.9416C408.294 53.9602 406.962 54.827 405.3 55.5418C403.638 56.2566 401.61 56.614 399.215 56.614ZM400.18 50.9311C402.682 50.9311 404.791 50.4397 406.507 49.4568C408.24 48.4739 409.545 47.2051 410.42 45.6503C411.314 44.0956 411.761 42.4604 411.761 40.7448V34.9547C411.493 35.2763 410.903 35.5712 409.991 35.8393C409.098 36.0895 408.061 36.3128 406.882 36.5094C405.72 36.6881 404.585 36.849 403.477 36.9919C402.387 37.117 401.503 37.2243 400.824 37.3136C399.18 37.5281 397.643 37.8765 396.213 38.3591C394.801 38.8237 393.657 39.5296 392.782 40.4767C391.924 41.406 391.495 42.6748 391.495 44.2832C391.495 46.4813 392.308 48.1433 393.934 49.2692C395.579 50.3771 397.661 50.9311 400.18 50.9311Z" fill="black"/>
            <path d="M376.081 17.9065H369.433C369.04 15.9943 368.352 14.3145 367.369 12.867C366.404 11.4194 365.225 10.2042 363.831 9.22132C362.455 8.22056 360.927 7.46998 359.247 6.9696C357.567 6.46922 355.816 6.21903 353.993 6.21903C350.669 6.21903 347.658 7.05895 344.959 8.73881C342.279 10.4187 340.143 12.8938 338.553 16.1641C336.98 19.4345 336.194 23.4465 336.194 28.2001C336.194 32.9537 336.98 36.9657 338.553 40.236C340.143 43.5064 342.279 45.9815 344.959 47.6613C347.658 49.3412 350.669 50.1811 353.993 50.1811C355.816 50.1811 357.567 49.9309 359.247 49.4306C360.927 48.9302 362.455 48.1885 363.831 47.2056C365.225 46.2049 366.404 44.9807 367.369 43.5332C368.352 42.0678 369.04 40.3879 369.433 38.4936H376.081C375.581 41.2994 374.67 43.8102 373.347 46.0262C372.025 48.2421 370.381 50.1275 368.415 51.6823C366.449 53.2192 364.242 54.3897 361.794 55.1939C359.363 55.9981 356.763 56.4002 353.993 56.4002C349.311 56.4002 345.147 55.2564 341.501 52.969C337.856 50.6815 334.988 47.429 332.897 43.2115C330.806 38.994 329.76 33.9902 329.76 28.2001C329.76 22.4099 330.806 17.4061 332.897 13.1886C334.988 8.97113 337.856 5.71865 341.501 3.43119C345.147 1.14373 349.311 0 353.993 0C356.763 0 359.363 0.402094 361.794 1.20628C364.242 2.01046 366.449 3.18993 368.415 4.74469C370.381 6.28158 372.025 8.15801 373.347 10.374C374.67 12.5721 375.581 15.0829 376.081 17.9065Z" fill="black"/>
            <path d="M274.541 31.8452V55.649H263.121V14.4748H274.005V21.7392H274.487C275.399 19.3446 276.926 17.4503 279.071 16.0563C281.215 14.6445 283.816 13.9386 286.872 13.9386C289.731 13.9386 292.224 14.5641 294.351 15.8151C296.477 17.066 298.13 18.8531 299.31 21.1763C300.489 23.4816 301.079 26.2337 301.079 29.4326V55.649H289.659V31.4699C289.677 28.9501 289.034 26.9843 287.729 25.5725C286.425 24.1429 284.629 23.428 282.341 23.428C280.804 23.428 279.446 23.7586 278.267 24.4199C277.105 25.0811 276.194 26.0461 275.533 27.3149C274.889 28.5659 274.559 30.076 274.541 31.8452Z" fill="black"/>
            <path d="M235.639 56.4532C231.403 56.4532 227.758 55.5954 224.702 53.8798C221.664 52.1464 219.323 49.6981 217.679 46.5349C216.035 43.3539 215.212 39.5921 215.212 35.2495C215.212 31.0142 216.035 27.297 217.679 24.0982C219.323 20.8993 221.637 18.4063 224.621 16.6193C227.624 14.8322 231.144 13.9386 235.183 13.9386C237.899 13.9386 240.428 14.3765 242.769 15.2521C245.128 16.1099 247.183 17.4056 248.935 19.139C250.704 20.8725 252.08 23.0527 253.063 25.6797C254.046 28.2889 254.537 31.3448 254.537 34.8474V37.9838H219.77V30.9069H243.788C243.788 29.2628 243.43 27.8064 242.716 26.5375C242.001 25.2687 241.009 24.2769 239.74 23.5621C238.489 22.8294 237.033 22.463 235.371 22.463C233.637 22.463 232.1 22.8651 230.76 23.6693C229.438 24.4556 228.401 25.5189 227.651 26.8592C226.9 28.1816 226.516 29.656 226.498 31.2822V38.0106C226.498 40.0478 226.873 41.8081 227.624 43.2914C228.392 44.7747 229.473 45.9184 230.867 46.7226C232.261 47.5268 233.914 47.9288 235.826 47.9288C237.095 47.9288 238.257 47.7501 239.311 47.3927C240.366 47.0353 241.268 46.4992 242.019 45.7844C242.769 45.0695 243.341 44.1939 243.734 43.1574L254.296 43.8543C253.76 46.392 252.661 48.6079 250.999 50.5022C249.355 52.3787 247.228 53.8441 244.619 54.8984C242.028 55.935 239.034 56.4532 235.639 56.4532Z" fill="black"/>
            <path d="M207.613 26.2159L197.159 26.8592C196.98 25.9657 196.596 25.1615 196.006 24.4467C195.416 23.714 194.639 23.1332 193.674 22.7043C192.727 22.2575 191.592 22.0341 190.27 22.0341C188.5 22.0341 187.008 22.4094 185.793 23.16C184.578 23.8927 183.97 24.8756 183.97 26.1086C183.97 27.0915 184.363 27.9225 185.15 28.6016C185.936 29.2807 187.285 29.8258 189.197 30.2368L196.649 31.7379C200.653 32.56 203.637 33.8824 205.603 35.7052C207.569 37.5281 208.551 39.9227 208.551 42.8893C208.551 45.5878 207.756 47.9557 206.166 49.9929C204.593 52.0302 202.431 53.6207 199.679 54.7644C196.944 55.8903 193.79 56.4532 190.216 56.4532C184.765 56.4532 180.423 55.3184 177.188 53.0488C173.971 50.7614 172.086 47.6519 171.532 43.7203L182.764 43.1306C183.103 44.7925 183.925 46.0614 185.23 46.937C186.535 47.7948 188.206 48.2237 190.243 48.2237C192.244 48.2237 193.853 47.8395 195.068 47.0711C196.301 46.2847 196.926 45.275 196.944 44.042C196.926 43.0055 196.489 42.1566 195.631 41.4954C194.773 40.8163 193.451 40.298 191.664 39.9406L184.533 38.5199C180.512 37.7157 177.519 36.3218 175.553 34.3381C173.605 32.3545 172.631 29.8258 172.631 26.752C172.631 24.1071 173.346 21.8286 174.776 19.9164C176.223 18.0042 178.252 16.5299 180.861 15.4934C183.488 14.4569 186.561 13.9386 190.082 13.9386C195.282 13.9386 199.375 15.0377 202.359 17.2358C205.361 19.4339 207.113 22.4273 207.613 26.2159Z" fill="black"/>
            <path d="M151.669 38.1182V14.4751H163.088V55.6494H152.124V48.1705H151.695C150.766 50.583 149.22 52.522 147.058 53.9874C144.913 55.4528 142.295 56.1855 139.204 56.1855C136.452 56.1855 134.03 55.56 131.939 54.3091C129.848 53.0581 128.213 51.28 127.034 48.9747C125.872 46.6693 125.282 43.9083 125.264 40.6916V14.4751H136.684V38.6543C136.702 41.0847 137.354 43.0058 138.641 44.4176C139.927 45.8294 141.652 46.5353 143.814 46.5353C145.19 46.5353 146.477 46.2226 147.674 45.5971C148.872 44.9537 149.837 44.0066 150.569 42.7556C151.32 41.5047 151.686 39.9589 151.669 38.1182Z" fill="black"/>
            <path d="M92.0854 56.4264C89.4584 56.4264 87.1174 55.9707 85.0622 55.0593C83.0071 54.13 81.3809 52.7629 80.1835 50.9579C79.004 49.1351 78.4143 46.8655 78.4143 44.1492C78.4143 41.8617 78.8343 39.9406 79.6742 38.3859C80.5141 36.8311 81.6578 35.5801 83.1054 34.633C84.5529 33.6858 86.197 32.971 88.0377 32.4885C89.8963 32.006 91.8442 31.6664 93.8815 31.4699C96.2761 31.2197 98.2062 30.9874 99.6716 30.7729C101.137 30.5406 102.2 30.201 102.862 29.7543C103.523 29.3075 103.853 28.6463 103.853 27.7706V27.6098C103.853 25.9121 103.317 24.5986 102.245 23.6693C101.191 22.74 99.6895 22.2754 97.7415 22.2754C95.6864 22.2754 94.0512 22.7311 92.836 23.6425C91.6208 24.536 90.8166 25.6619 90.4235 27.02L79.8618 26.1623C80.398 23.6603 81.4523 21.498 83.025 19.6752C84.5976 17.8345 86.6259 16.4227 89.11 15.4398C91.6119 14.439 94.5069 13.9386 97.7952 13.9386C100.083 13.9386 102.272 14.2067 104.363 14.7428C106.471 15.279 108.339 16.1099 109.965 17.2358C111.609 18.3617 112.905 19.8092 113.852 21.5784C114.799 23.3297 115.273 25.4295 115.273 27.8778V55.649H104.443V49.9393H104.121C103.46 51.226 102.576 52.3608 101.468 53.3437C100.36 54.3087 99.0282 55.0682 97.4735 55.6222C95.9187 56.1583 94.1227 56.4264 92.0854 56.4264ZM95.3558 48.5454C97.0356 48.5454 98.5189 48.2148 99.8056 47.5536C101.092 46.8745 102.102 45.9631 102.835 44.8193C103.567 43.6756 103.934 42.38 103.934 40.9324V36.563C103.576 36.7954 103.085 37.0098 102.459 37.2064C101.852 37.3851 101.164 37.5549 100.395 37.7157C99.6269 37.8587 98.8585 37.9927 98.09 38.1178C97.3216 38.225 96.6246 38.3233 95.9991 38.4127C94.6588 38.6092 93.4883 38.922 92.4875 39.3509C91.4868 39.7798 90.7094 40.3606 90.1554 41.0933C89.6014 41.8081 89.3244 42.7016 89.3244 43.7739C89.3244 45.3287 89.8873 46.5171 91.0132 47.3391C92.1569 48.1433 93.6045 48.5454 95.3558 48.5454Z" fill="black"/>
            <path d="M70.0643 0.750473V55.6495H58.6449V0.750473H70.0643Z" fill="black"/>
            <path d="M49.4037 19.9706H37.6626C37.4482 18.4516 37.0104 17.1023 36.3491 15.9229C35.6879 14.7255 34.8391 13.7069 33.8026 12.867C32.7661 12.027 31.5687 11.3837 30.2105 10.9369C28.8702 10.4901 27.4138 10.2668 25.8411 10.2668C22.9997 10.2668 20.5246 10.9727 18.4158 12.3844C16.3071 13.7784 14.6719 15.8156 13.5103 18.4962C12.3487 21.159 11.7679 24.3936 11.7679 28.2001C11.7679 32.1138 12.3487 35.402 13.5103 38.0647C14.6898 40.7275 16.3339 42.7379 18.4426 44.0961C20.5514 45.4543 22.9907 46.1334 25.7607 46.1334C27.3155 46.1334 28.7541 45.9279 30.0765 45.5169C31.4168 45.1058 32.6052 44.5072 33.6417 43.7208C34.6782 42.9167 35.536 41.9427 36.2151 40.799C36.9121 39.6552 37.3946 38.3507 37.6626 36.8853L49.4037 36.9389C49.0999 39.4587 48.3404 41.8891 47.1252 44.2302C45.9279 46.5534 44.3106 48.6353 42.2733 50.476C40.2539 52.2988 37.8414 53.7463 35.0356 54.8186C32.2478 55.873 29.0936 56.4002 25.5731 56.4002C20.6765 56.4002 16.2981 55.2922 12.4381 53.0762C8.59584 50.8602 5.55781 47.6524 3.32396 43.4528C1.10799 39.2531 0 34.1689 0 28.2001C0 22.2134 1.12586 17.1202 3.37758 12.9206C5.62929 8.72094 8.6852 5.52207 12.5453 3.32397C16.4054 1.10799 20.748 0 25.5731 0C28.7541 0 31.7027 0.44677 34.4191 1.34031C37.1533 2.23385 39.5748 3.53841 41.6836 5.254C43.7923 6.95173 45.5079 9.03367 46.8304 11.4998C48.1707 13.966 49.0285 16.7896 49.4037 19.9706Z" fill="black"/>
          </svg>
      </div>
    </section>  
  );
}

export default App;
